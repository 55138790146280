import { appInject, appInjectable } from "@core/di/utils";
import { BaseService } from "@core/services/base";
import { DI_TOKENS } from "@shared/constants/di";
import { IConfigService } from "@shared/interfaces/config-service.interface";
import { IPlayerCardService } from "@shared/interfaces/player-card.interface";
import { IHttpClientService } from "@shared/interfaces/http-client-service.interface";
import { PlayerCardsListPaginatedDto } from "@shared/types/player-card/player-card-list-paginated.dto";
import { PlayerCardDto } from "@shared/types/player-card/player-card.dto";

@appInjectable()
export class PlayerCardService
  extends BaseService
  implements IPlayerCardService
{
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private httpClient = appInject<IHttpClientService>(
    DI_TOKENS.httpClientService,
  );
  private baseURL: string;

  constructor() {
    super();
    this.baseURL = this.configService.baseUrl;
  }

  async create(dto: PlayerCardDto): Promise<PlayerCardDto> {
    const { data } = await this.httpClient.post<PlayerCardDto>(
      "/admin/create-player-card",
      {
        ...dto,
        draftYear: Number(dto.draftYear),
      },
      {
        baseURL: this.baseURL,
      },
    );

    return data;
  }

  async update(dto: PlayerCardDto): Promise<PlayerCardDto> {
    const { data } = await this.httpClient.post<PlayerCardDto>(
      `/admin/update-player-card/${dto.id}`,
      {
        ...dto,
        draftYear: Number(dto.draftYear),
      },
      {
        baseURL: this.baseURL,
      },
    );

    return data;
  }

  async getPlayerCardsList(dto: {
    page?: number;
    pageSize?: number;
  }): Promise<PlayerCardsListPaginatedDto> {
    const { data } = await this.httpClient.get<PlayerCardsListPaginatedDto>(
      "/player-card/list",
      {
        params: dto,
        baseURL: this.baseURL,
      },
    );

    return data;
  }
}
